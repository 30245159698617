import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import "./fonts.css";
import "./all.sass";
import "./extra.css";

import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html class="has-navbar-fixed-top" lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/gf-gfsi-extreme-55-proc-1-.png`}
        />
      </Helmet>
      <div className="steute-colors">
        <div className="is-bg-steute-red"></div>
        <div className="is-bg-steute-blue"></div>
        <div className="is-bg-steute-yellow"></div>
        <div className="is-bg-steute-green"></div>
      </div>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
