import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import CookieConsent from "react-cookie-consent";

import logo from "../img/steute-logo-light.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        {/* <div className="attention-contact-mail attention-link"> */}
        <Link
          className="attention-contact-mail attention-link"
          to="/kontakt"
          activeStyle={{ display: "none" }}
        >
          <div className="inner-circle-small">
            <FontAwesomeIcon
              className="is-size-3 is-centered-icon animate-flicker"
              icon={faEnvelope}
            />
          </div>
        </Link>

        <Link
          className="attention-contact-phone attention-link"
          to="/kontakt"
          activeStyle={{ display: "none" }}
        >
          <a href="tel:+48228430820">
            <div className="inner-circle-small">
              <FontAwesomeIcon
                className="is-size-3 is-centered-icon animate-flicker"
                icon={faMobileAlt}
              />
            </div>
          </a>
        </Link>

        {/* </div> */}

        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section>
                  <img
                    src={logo}
                    alt="steute"
                    style={{ width: "14em", marginTop: "1em" }}
                  />
                </section>
              </div>

              <div className="column is-4">
                <section>
                  <ul className="is-size-5 footer-list">
                    <li>steute Polska</li>
                    <li>biuro handlowe</li>
                    <li>al. Wilanowska 321</li>
                    <li>02-665 Warszawa</li>
                    <li className="mt-1 footer-contact">
                      <a
                        className="has-text-weight-semibold"
                        href="tel:+48228430820"
                      >
                        + 48 22 843 08 20
                      </a>
                    </li>
                    <li className="footer-contact">
                      <a
                        className=" has-text-weight-semibold"
                        href="mailto:info@steute.pl?subject=Zapytanie ze strony wylaczniki-nozne.pl"
                      >
                        info@steute.pl
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list is-size-5">
                    <li>
                      <Link className="navbar-item" to="/kontakt">
                        Szybki kontakt
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/produkty">
                        Produkty
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/baza-wiedzy">
                        Baza wiedzy
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/poznaj-steute">
                        Poznaj steute
                      </Link>
                    </li>
                    <li>
                      <Link to="/" className="navbar-item">
                        Strona główna
                      </Link>
                    </li>
                    <li>
                      <Link to="/od-wydawcy" className="navbar-item">
                        Od wydawcy
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="has-text-centered">
          <p>© steute Polska 2021</p>
        </div>
        <div className="cookie-consent">
          <CookieConsent buttonText="Rozumiem">
            Na naszych stronach stosujemy pliki cookies w celu świadczenia
            Państwu usług na najwyższym poziomie. Korzystanie ze strony bez
            zmiany ustawień dotyczących cookies, oznacza zgodę na ich użycie na
            urządzeniu końcowym. Możecie Państwo w każdej chwili dokonać zmiany
            ustawień dotyczących cookies. Więcej informacji oraz polityka
            prywatności jest dostępna{" "}
            <a
              href="http://polityka-prywatnosci.steute.pl"
              target="_blank"
              rel="noopener noreferrer"
            >
              tutaj
            </a>
            .
          </CookieConsent>
        </div>
      </footer>
    );
  }
};

export default Footer;
